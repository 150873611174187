/*------------------------------------*
 * #COLORS */
/*------------------------------------ */

$main-color: #2e3192;
$main-color--darker: darken($main-color, 10%);
$main-color--lighter: lighten($main-color, 10%);

$secondary-color: #58595b;

$third-color:#c7150c;

$text-color: #fff;
$secondary-text-color: #111111;

$btn-text: #ffffff;
$gray: #333;
$light-gray: #d5d6db;
$yellow: blue;

/*------------------------------------*
 * #BUTTONS */
/*------------------------------------ */

.btn-main-color {
  background-color: $main-color;

  &:hover {
    background-color: $main-color--lighter;
  }
}

.btn-secondary-color {
  color: #666 !important;
  background: $secondary-color;
}

.remodal-overlay {
  background: rgba(0,0,0,.8)!important;
}

/*------------------------------------*
 * #TITLES */
/*------------------------------------ */

$title-color: #58595b;
$subtitle-color: #111111;
$text-color: #111111;